import {
  LOAD_BLOG_SUCCESS,
  LOAD_BLOG_FAILURE,
} from "../actions/blogFauturedActionTypes";

const initState = {
  blogFeatured: [],
};

const blogFeaturedReducer = (state = initState, action) => {
  if (action.type === LOAD_BLOG_SUCCESS) {
    return {
      ...state,
      blogFeatured: action.payload,
    };
  }
  return state;
};

export default blogFeaturedReducer;
