import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {load, save} from "redux-localstorage-simple";
import thunk from "redux-thunk";
// import products from "./data/products.json";
import App from "./App";
import "./assets/scss/style.scss";
import {fetchProducts} from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
import * as serviceWorker from "./serviceWorker";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);
//
// fetch("http://127.0.0.1:8000/api/products")
//   .then((response) => response.json())
//   .then((products) => {
//     console.log("son_kisim", products);
//     store.dispatch(fetchProducts(products));
//   });

// fetch products from json file
store.dispatch(fetchProducts());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
