import {
  LOAD_USER_PROFILE_SUCCESS,
  LOAD_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  POST_USER_PROFILE_SUCCESS,
  POST_USER_PROFILE_FAIL,
} from "../actions/profileActionTypes";

const initialState = {
  first_name: null,
  last_name: null,
  email_address: null,
  phone: null,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_PROFILE_SUCCESS:
      return {
        ...state,
        first_name: action.payload.profile.first_name,
        last_name: action.payload.profile.last_name,
        email_address: action.payload.profile.email_address,
        phone: action.payload.profile.phone,
      };
    case LOAD_USER_PROFILE_FAIL:
      return {
        ...state,
        first_name: null,
        last_name: null,
        email_address: null,
        phone: null,
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        first_name: action.payload.profile.first_name,
        last_name: action.payload.profile.last_name,
        email_address: action.payload.profile.email_address,
        phone: action.payload.profile.phone,
      };
    case UPDATE_USER_PROFILE_FAIL:
      return {
        ...state,
        first_name: null,
        last_name: null,
        email_address: null,
        phone: null,
      };
    case POST_USER_PROFILE_SUCCESS:
      return {
        ...state,
        first_name: action.payload.profile.first_name,
        last_name: action.payload.profile.last_name,
        email_address: action.payload.profile.email_address,
        phone: action.payload.profile.phone,
      };
    case POST_USER_PROFILE_FAIL:
      return {
        ...state,
        first_name: null,
        last_name: null,
        email_address: null,
        phone: null,
      };
    default:
      return state;
  }
};

export default profileReducer;

//selectors
export const getProfile = (state) => state.profileReducer;
