import {
  LOAD_USER_ADDRESS_FAILURE,
  LOAD_USER_ADDRESS_SUCCESS,
  POST_USER_ADDRESS_FAILURE,
  POST_USER_ADDRESS_SUCCESS,
  UPDATE_USER_ADDRESS_FAILURE,
  UPDATE_USER_ADDRESS_SUCCESS,
} from "../actions/addressActionTypes";

const initialState = {
  address_first_name: null,
  address_last_name: null,
  address_email_address: null,
  address_phone: null,
  address1: null,
  address2: null,
  zip_code: null,
  town_city: null,
  country: null,
  additional_information: null,
  state_country: null,
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_ADDRESS_SUCCESS:
      if (action.payload.data.length === 0) {
        return {
          address_first_name: "",
          address_last_name: "",
          address_email_address: "",
          address_phone: "",
          address1: "",
          address2: "",
          zip_code: "",
          town_city: "",
          country: "",
          additional_information: "",
          state_country: "",
        };
      } else {
        return {
          ...state,
          address_first_name: action.payload.data.address_first_name,
          address_last_name: action.payload.data.address_last_name,
          address_email_address: action.payload.data.address_email_address,
          address_phone: action.payload.data.address_phone,
          address1: action.payload.data.address1,
          address2: action.payload.data.address2,
          zip_code: action.payload.data.zip_code,
          town_city: action.payload.data.town_city,
          country: action.payload.data.country,
          additional_information: action.payload.data.additional_information,
          state_country: action.payload.data.state_country,
        };
      }
    case LOAD_USER_ADDRESS_FAILURE:
      return {
        ...state,
        address_first_name: null,
        address_last_name: null,
        address_email_address: null,
        address_phone: null,
        address1: null,
        address2: null,
        zip_code: null,
        town_city: null,
        country: null,
        additional_information: null,
        state_country: null,
      };
    case UPDATE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        address_first_name: action.payload.data.address_first_name,
        address_last_name: action.payload.data.address_last_name,
        address_email_address: action.payload.data.address_email_address,
        address_phone: action.payload.data.address_phone,
        address1: action.payload.data.address1,
        address2: action.payload.data.address2,
        zip_code: action.payload.data.zip_code,
        town_city: action.payload.data.town_city,
        country: action.payload.data.country,
        additional_information: action.payload.data.additional_information,
        state_country: action.payload.data.state_country,
      };
    case UPDATE_USER_ADDRESS_FAILURE:
      return {
        ...state,
        address_first_name: null,
        address_last_name: null,
        address_email_address: null,
        address_phone: null,
        address1: null,
        address2: null,
        zip_code: null,
        town_city: null,
        country: null,
        additional_information: null,
        state_country: null,
      };
    case POST_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        address_first_name: action.payload.data.address_first_name,
        address_last_name: action.payload.data.address_last_name,
        address_email_address: action.payload.data.address_email_address,
        address_phone: action.payload.data.address_phone,
        address1: action.payload.data.address1,
        address2: action.payload.data.address2,
        zip_code: action.payload.data.zip_code,
        town_city: action.payload.data.town_city,
        country: action.payload.data.country,
        additional_information: action.payload.data.additional_information,
        state_country: action.payload.data.state_country,
      };
    case POST_USER_ADDRESS_FAILURE:
      return {
        ...state,
        address_first_name: null,
        address_last_name: null,
        address_email_address: null,
        address_phone: null,
        address1: null,
        address2: null,
        zip_code: null,
        town_city: null,
        country: null,
        additional_information: null,
        state_country: null,
      };

    default:
      return state;
  }
};
export default addressReducer;

export const getAddress = (state) => state.addressReducer;
// make it not refresh if state does not change
