import blogFeaturedReducer from "./blogFeauturedReducer";
import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import homefashionReducer from "./homefashionReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import authReducer from "./authReducer";
import newAuthReducer from "./newAuthReducer";
import profileReducer from "./profileReducer";
import addressReducer from "./addressReducer";

const appReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  homeFashionData: homefashionReducer,
  authReducer: authReducer,
  newAuthData: newAuthReducer,
  profileReducer: profileReducer,
  addressReducer: addressReducer,
  blogFeaturedReducer: blogFeaturedReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
