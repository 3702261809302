import axios from "axios";
import {getMainPageSlider} from "../../services/services";
import * as settings from "../../settings";

export const FETCH_SLIDER_SUCCESS = "FETCH_SLIDER_SUCCESS";

const fetchSliderSuccess = (slides) => (
  {
    type: FETCH_SLIDER_SUCCESS,
    payload: slides,
  }
);

// fetch products
export const fetchSlides = () => {
  console.log(`${settings.API_SERVER}/api/mainpageslider`);
  return (dispatch) => {
    getMainPageSlider()
      .then((response) => {
        const slides = response.data;
        dispatch(fetchSliderSuccess(slides));
      })
      .catch((error) => {
        const errorMsg = error.message;
        console.log("error api");
        console.log(errorMsg);
      });
  };
};
