import Cookies from "js-cookie";
import axios from "../helpers/axios";
import * as settings from "../settings";

// Products
export const getAllTheProducts = () => {
  return axios.get(`${settings.API_SERVER}/api/products`);
};
// Products___

// MainPageSlider
export const getMainPageSlider = () => {
  return axios.get(`${settings.API_SERVER}/api/mainpageslider`);
};
// MainPageSlider___

// Profile Services

export const registerProfile = (data) => {
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRFToken": Cookies.get("csrftoken"),
  };

  return axios.post(`${settings.API_SERVER}/api/account/register`, data, {
    headers: headers,
  });
};

export const postProfileInfo = (data) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
  };
  return axios.post(
    `${settings.API_SERVER}/api/user_profile/user/`,
    data,
    config
  );
};

export const getProfileInfo = () => {
  axios.defaults.withCredentials = true;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${settings.API_SERVER}/api/user_profile/user/`, config);
};

export const putProfileInfo = (data) => {
  axios.defaults.withCredentials = true;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
  };
  return axios.put(
    `${settings.API_SERVER}/api/user_profile/user/`,
    data,
    config
  );
};
// Profile Services___

// Auth Services
export const postAuthLogOut = () => {
  const token = localStorage.getItem("token");
  return axios.post(
    `${settings.API_SERVER}/api/auth/logout/`,
    {},
    { headers: { Authorization: `Token ${token}` } }
  );
};

export const postAuthLogin = (username, password) => {
  axios.defaults.withCredentials = true;
  return axios.post(`${settings.API_SERVER}/api/auth/login/`, {
    username: username,
    password: password,
  });
};

export const updatePassword = (data) => {
  axios.defaults.withCredentials = true;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
  };
  return axios.post(
    `${settings.API_SERVER}/api/auth/update_password/`,
    data,
    config
  );
};

export const getBlogFeatured = () => {
  return axios.get(`${settings.API_SERVER}/api/blog-feautured`);
};

export const subscribe = (data) => {
  return axios.post(`${settings.API_SERVER}/api/user-subscription`, data);
};

export const userContactUs = (data) => {
  return axios.post(`${settings.API_SERVER}/api/user-contact-us`, data);
};

export const customerOrders = () => {
  axios.defaults.withCredentials = true;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
  };
  return axios.get(`${settings.API_SERVER}/api/customerOrders/`, config);
};
