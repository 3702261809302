import { FETCH_SLIDER_SUCCESS } from "../actions/homefashionActions";

const initState = {
  slides: [],
};

const homefashionReducer = (state = initState, action) => {
  if (action.type === FETCH_SLIDER_SUCCESS) {
    return {
      ...state,
      slides: action.payload,
    };
  }

  return state;
};

export default homefashionReducer;
