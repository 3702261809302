import axios from "axios";
import {getAllTheProducts} from "../../services/services";
import * as settings from "../../settings";

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

const fetchProductsSuccess = (products) => (
  {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: products,
  }
);

// fetch products
export const fetchProducts = () => {
  console.log(`${settings.API_SERVER}/api/products`);
  return (dispatch) => {
    getAllTheProducts()
      .then((response) => {
        const products = response.data;
        dispatch(fetchProductsSuccess(products));
      })
      .catch((error) => {
        const errorMsg = error.message;
        console.log(errorMsg);
        console.log("error");
      });
  };
};
