import axios from "axios";

export const SET_CURRENCY = "SET_CURRENCY";

export const setCurrency = (currencyName) => {
  return (dispatch) => {
    // add access key to axios header
    axios.defaults.headers.common["apikey"] =
      "IJCnjT5Y0hGwhFt7L1aUMy0Vl7afJtp8";
    axios
      // .get(`https://api.exchangeratesapi.io/latest?base=USD`)
      .get(`https://api.apilayer.com/exchangerates_data/latest?&base=usd`)
      .then((response) => {
        const rates = response.data.rates;
        let currencyRate = 0;
        for (const rate in rates) {
          if (rate === currencyName) {
            currencyRate = rates[rate];
          }
        }
        dispatch({
          type: SET_CURRENCY,
          payload: { currencyName, currencyRate },
        });
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };
};
